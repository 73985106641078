export const LINE_BREAK = '\n';

export class StringUtils {
    static isTrue(str: string): boolean {
        return str == 'true' || str == 'True';
    }

    static isEmpty(str: string | null | undefined): boolean {
        return str == null || str == '';
    }

    static isNotEmpty(str: string | null | undefined): boolean {
        return !this.isEmpty(str);
    }

    static getOrDefault(str: string | number | null, defaultVal: string | null): string | null {
        return str != null && str != '' ? str.toString() : defaultVal;
    }

    static convertLowerCamelCaseToSentence(str: string): string {
        return (
            str
                // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, (str: string) => str.toUpperCase())
        );
    }

    static convertUpperCaseUnderScoreToSentence(str: string): string {
        return str
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    static convertSentenceToUnderscoreLowerCaseStr(str: string): string {
        return str.replace(/\s+/g, '_').toLowerCase();
    }

    static isEqualIgnoreCaseWithoutSpaces(first: string, second: string): boolean {
        return first != null && second != null && this.removeSpaces(first).toLowerCase() === this.removeSpaces(second).toLowerCase();
    }

    static removeSpaces(str: string): string {
        return str.replace(' ', '');
    }

    static sortAlphabetically(first: string | null, second: string | null): number {
        if (first == null) {
            return 1;
        }

        if (second == null) {
            return -1;
        }

        if (first < second) {
            return -1;
        }

        if (first > second) {
            return 1;
        }

        return 0;
    }

    static sortAlphabeticallyByIgnoringCase(first: string | null, second: string | null): number {
        if (first == null) {
            return 1;
        }

        if (second == null) {
            return -1;
        }

        if (first.toLowerCase() < second.toLowerCase()) {
            return -1;
        }

        if (first.toLowerCase() > second.toLowerCase()) {
            return 1;
        }

        return 0;
    }

    static getStringValue(value: string | null): string {
        if (value == null) {
            return '';
        }
        return value;
    }

    /**
     * Comparator to sort stings in ascending order.
     * Only strings that differ in base letters or accents and other diacritic marks compare as unequal.
     * Examples: a ≠ b, a ≠ á, a = A.
     * @param a
     * @param b
     * @returns
     */
    static numericCollationSortComparator(a: string, b: string): number {
        return new Intl.Collator('en', {
            numeric: true,
            sensitivity: 'accent'
        }).compare(a, b);
    }
}
